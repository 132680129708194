import React, { Component } from 'react';
import { ArrowLeftCircleFill } from 'react-bootstrap-icons';
import NumberFormat from 'react-number-format';
import '../index.css';
import {Box} from './Box'
import {Error} from './Error'
class PersonalCalculator extends Component {
    state = { 
        // Initial values
        monthlyNetSalary: '',
        desiredLoanAmount: '',
        loanDeduction: '',
         // 3 months
        color3: '#3A974C',
        advanceInterest3: '2.25%',
        documentaryStamps3: '0.19%',
        lifeInsurance3: '0.36%',
        deduction3: '0.00',
        totalEstDeduction3: '0.00',
        interestValue3: '0.00',
        docStampsValue3: '0.00',
        lifeInsuranceValue3: '0.00',
        totalDeductions3: '0.00',
        loanableAmount3: '0.00',
        deduction_cutoff3: '0.00', 
         // 6 months
        color6: '#3A974C',
        advanceInterest6: '4.5%',
        documentaryStamps6: '0.38%',
        lifeInsurance6: '0.71%',
        deduction6: '0.00',
        totalEstDeduction6: '0.00',
        interestValue6: '0.00',
        docStampsValue6: '0.00',
        lifeInsuranceValue6: '0.00',
        totalDeductions6: '0.00',
        loanableAmount6: '0.00',
        deduction_cutoff6: '0.00', 
         // 12 months
        color12: '#3A974C',
        advanceInterest12: '9.00%',
        documentaryStamps12: '0.75%',
        lifeInsurance12: '1.26%',
        deduction12: '0.00',
        totalEstDeduction12: '0.00',
        desiredLoan: '0.00',
        interestValue12: '0.00',
        docStampsValue12: '0.00',
        lifeInsuranceValue12: '0.00',
        totalDeductions12: '0.00',
        loanableAmount12: '0.00',
        deduction_cutoff12: '0.00', 
    } 

    computeLoan = (monthlyNetSalary, desiredLoanAmount, loanDeduction) => {
        const net = parseFloat(monthlyNetSalary.replace(/,/g,''));
        const dla = parseFloat(desiredLoanAmount.replace(/,/g,''));
        const PERCENTAGES ={
            'interestValue3' :0.0225,
            'docStampsValue3': 0.001875, 
            'lifeInsuranceValue3': 0.0036,
            'interestValue6' :0.045,
            'docStampsValue6': 0.0038, 
            'lifeInsuranceValue6': 0.0071,
            'interestValue12': 0.09,
            'docStampsValue12': 0.0075, 
            'lifeInsuranceValue12': 0.0126
        };
        
        const netEvaluated = net * 3;
        const halfSalary  = net/2;
        //  Calculations for 3 months
        const minusPerCutOff3 = dla/3;
        const totalLoan3 = minusPerCutOff3;
        const newInterestValue3 = dla * PERCENTAGES['interestValue3'];
        const newdocStampsValue3 = dla * PERCENTAGES['docStampsValue3'];
        const newLifeInsuranceValue3 = dla* PERCENTAGES['lifeInsuranceValue3'];
        const newTotalDeductions3 = newInterestValue3 + newdocStampsValue3 + newLifeInsuranceValue3;
        const newLoanableAmount3 = dla - newTotalDeductions3;
        
        //  Calculations for 6 months
        const minusPerCutOff6 = dla/6;
        const totalLoan6 = minusPerCutOff6;
        const newInterestValue6 = dla * PERCENTAGES['interestValue6'];    
        const newdocStampsValue6 = dla * PERCENTAGES['docStampsValue6'];
        const newLifeInsuranceValue6 = dla* PERCENTAGES['lifeInsuranceValue6'];
        const newTotalDeductions6 = newInterestValue6 + newdocStampsValue6 + newLifeInsuranceValue6;
        const newLoanableAmount6 = dla - newTotalDeductions6;

        //  Calculations for 12 months
        const minusPerCutOff12 = dla/12;
        const totalLoan12 = minusPerCutOff12;
        const newInterestValue12 = dla * PERCENTAGES['interestValue12'];
        const newdocStampsValue12 = dla * PERCENTAGES['docStampsValue12'];
        const newLifeInsuranceValue12= dla* PERCENTAGES['lifeInsuranceValue12'];
        const newTotalDeductions12 = newInterestValue12 + newdocStampsValue12 + newLifeInsuranceValue12;
        const newLoanableAmount12 = dla - newTotalDeductions12;
        
        const minusPerCutOff24 = dla/24;
        this.setState({
        // 3 months
            color3: this.evaluateTheme(minusPerCutOff6, halfSalary),
            deduction3: this.formatNum(minusPerCutOff3),
            totalEstDeduction3: this.formatNum(totalLoan3),
            interestValue3: this.formatNum(newInterestValue3),
            docStampsValue3: this.formatNum(newdocStampsValue3),
            lifeInsuranceValue3: this.formatNum(newLifeInsuranceValue3),
            totalDeductions3: this.formatNum(newTotalDeductions3),
            loanableAmount3: this.formatNum(newLoanableAmount3),
            deduction_cutoff3: this.formatNum(minusPerCutOff6),
        // 6 months
            color6: this.evaluateTheme(minusPerCutOff12, halfSalary),
            deduction6: this.formatNum(minusPerCutOff6),
            totalEstDeduction6: this.formatNum(totalLoan6),
            interestValue6: this.formatNum(newInterestValue6),
            docStampsValue6: this.formatNum(newdocStampsValue6),
            lifeInsuranceValue6: this.formatNum(newLifeInsuranceValue6),
            loanableAmount6: this.formatNum(newLoanableAmount6),
            deduction_cutoff6: this.formatNum(minusPerCutOff12),
        // 12 months
            color12: this.evaluateTheme(minusPerCutOff24, halfSalary),
            deduction12: this.formatNum(minusPerCutOff12),
            totalEstDeduction12: this.formatNum(totalLoan12),
            interestValue12: this.formatNum(newInterestValue12),
            docStampsValue12: this.formatNum(newdocStampsValue12),
            lifeInsuranceValue12: this.formatNum(newLifeInsuranceValue12),
            totalDeductions6: this.formatNum(newTotalDeductions6),
            totalDeductions12: this.formatNum(newTotalDeductions12),
            loanableAmount12: this.formatNum(newLoanableAmount12),
            deduction_cutoff12: this.formatNum(minusPerCutOff24),
            // Assumed Salary Value (Loan Amount / 3)
            netEvaluated,
            dla,
        });
    }
    
    evaluateTheme = (minusPerCutOff, halfSalary) => {
        const green = '#3A974C';
        const red = '#D11A2A';
        return minusPerCutOff >= halfSalary ? red : green;
    }
    
    hexToRgb = (hex) => {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, 0.1)`
            : null;
    }
    
    formatNum = (num) => {
        return isNaN(num) ? 0 : Number(num.toFixed(2)).toLocaleString('en');
    }
    render(){
        const {
            monthlyNetSalary, 
            desiredLoanAmount,
            loanDeduction,
            netEvaluated,
            dla,
        // 3 months
            color3,
            advanceInterest3,
            documentaryStamps3,
            lifeInsurance3,
            deduction3,
            interestValue3,
            docStampsValue3,
            lifeInsuranceValue3,
            totalDeductions3,
            loanableAmount3,
            deduction_cutoff3,
        // 6 months
            color6,
            advanceInterest6,
            documentaryStamps6,
            lifeInsurance6,
            deduction6,
            interestValue6,
            docStampsValue6, 
            lifeInsuranceValue6,
            totalDeductions6,
            loanableAmount6,
            deduction_cutoff6,
        // 12 months
            color12,
            advanceInterest12,
            documentaryStamps12,
            lifeInsurance12,
            deduction12,
            interestValue12,
            docStampsValue12,
            lifeInsuranceValue12,
            totalDeductions12,
            loanableAmount12,
            deduction_cutoff12,
        } = this.state; 

        const style3 = {
            color: color3,
            background: this.hexToRgb(color3)  
         }
        const style6 = {
            color: color6,
            background: this.hexToRgb(color6)
        }
        const style12 = {
            color: color12,
            background: this.hexToRgb(color12)
        }
        return(
        <div>
            <div className='top-left'>
            <ArrowLeftCircleFill style={{'fontSize': '24px'}}/> 
            <a href="https://sites.google.com/shopee.com/shopee-ph-intranet">
                Back to Intranet</a>
            </div>
            
            <div className='container'>
                <div className='calculator'>
                    <h1>Salary Loan Calculator</h1>
                    <span className='span0'><b>DISCLAIMER:</b> This is only a tool that allows you to compute your 
                    loan eligibility and project monthly deduction per cut-off. For better reference,
                     you may check your payslip via HRIS for actual deductions of existing loans, 
                     should you have any. <br></br><small><b>NOTE: Subject to Bank Approval</b></small></span>
                </div>
                <form className='calc-form' autoComplete='off'>
                        <div className='form-group'>
                            <label>Monthly Net Salary<br></br><strong>(Net Salary = Gross Salary - deductions including loans)</strong></label>
                            <span className='special'>₱ </span>
                            <NumberFormat thousandSeparator={true} placeholder='0.00' 
                            onInput={e => this.setState({'monthlyNetSalary': e.target.value})}></NumberFormat>
                        </div>
                        
                        <div className='form-group'>
                            <label>Desired Loan Amount <br></br>(up to <b>3x</b> of the <b>net salary</b>) </label>
                            <span className='special'>₱ </span>
                            <NumberFormat thousandSeparator={true} placeholder='0.00' 
                            onInput={e => this.setState({'desiredLoanAmount': e.target.value})}></NumberFormat>
                        </div>
                        { dla > netEvaluated ? <Error/>: ''} 
                        <div className='form-group'>
                            <input className='btn' type='button' value='Compute' 
                            onClick={() => this.computeLoan(monthlyNetSalary,desiredLoanAmount,loanDeduction)}>
                            </input>
                        </div>

                    </form>
                <h3>Result</h3>
                <div className='outer-wrapper'>
                    <div className='block-wrapper-personal'>
                        <Box 
                            months = '3'
                            months_cutoff= '6'
                            style = {style3}
                            advanceInterest = {advanceInterest3}
                            documentaryStamp = {documentaryStamps3}
                            lifeInsurance = {lifeInsurance3}
                            interestValue = {interestValue3}
                            docStampsValue = {docStampsValue3}
                            lifeInsuranceValue = {lifeInsuranceValue3}
                            totalDeductions = {totalDeductions3}
                            loanableAmount = {loanableAmount3}
                            deduction_cutoff = {deduction_cutoff3}
                            deduction = {deduction3}
                        /> 
                        <Box 
                            months = '6'
                            months_cutoff= '12'
                            style = {style6}
                            advanceInterest = {advanceInterest6}
                            documentaryStamp = {documentaryStamps6}
                            lifeInsurance = {lifeInsurance6}
                            interestValue = {interestValue6}
                            docStampsValue = {docStampsValue6}
                            lifeInsuranceValue = {lifeInsuranceValue6}
                            totalDeductions = {totalDeductions6}
                            loanableAmount = {loanableAmount6}
                            deduction_cutoff = {deduction_cutoff6}
                            deduction = {deduction6}
                        /> 
                        <Box 
                            months = '12'
                            months_cutoff= '24'
                            style = {style12}
                            advanceInterest = {advanceInterest12}
                            documentaryStamp = {documentaryStamps12}
                            lifeInsurance = {lifeInsurance12}
                            interestValue = {interestValue12}
                            docStampsValue = {docStampsValue12}
                            lifeInsuranceValue = {lifeInsuranceValue12}
                            totalDeductions = {totalDeductions12}
                            loanableAmount = {loanableAmount12}
                            deduction_cutoff = {deduction_cutoff12}
                            deduction = {deduction12}
                        /> 
                    </div>
                </div>

                    <div className='small-block'>
                        <p><b>NOTE</b><br></br>
                        <span style={{'color': '#3A974C'}}>Green</span> if cut off deduction
                        &lt;50% monthly net salary<br></br>
                        <span style={{'color': '#D11A2A'}}>Red</span> if cut off deduction
                        &ge;50% monthly net salary 
                        </p>
                    </div>

            </div>
        </div>
        )
    }
}

export default PersonalCalculator;
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'; 
import Base from './Base';

ReactDOM.render(

  <React.StrictMode>
    <Base />
  </React.StrictMode>,
  document.getElementById('root')
);

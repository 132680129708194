import '../index.css';

export function Box(props) {
    return(
        <div className='block-personal' style={props.style}>
            <h4>{props.months} Months Deduction</h4>
                <table>
                    <tbody>
                        <tr><td className='td-label'>Advance Interest ({props.advanceInterest})</td><td className='td-value'> ₱ {props.interestValue}</td></tr>
                        <tr><td className='td-label'>Documentary Stamps({props.documentaryStamp})</td><td className='td-value'>₱ {props.docStampsValue}</td></tr>
                        <tr><td className='td-label'>Life Insurance({props.lifeInsurance})</td><td className='td-value'>₱ {props.lifeInsuranceValue}</td></tr>
                        <tr><td className='td-deductions'>TOTAL DEDUCTIONS</td><td className='td-value'>=₱ {props.totalDeductions}</td></tr>
                        <tr><td className='loan-amount'>LOANABLE AMOUNT</td><td className='td-value'><b>₱ {props.loanableAmount}</b></td></tr>
                    </tbody>
                </table>    
                    <p className='p2'>Deduction for {props.months_cutoff} pay salary cut offs <span className='span4'><br></br><b>Php {props.deduction_cutoff}</b></span></p>
                    <p className='p2'>Monthly Deduction for {props.months} months <span className='span3'><br></br><b>Php {props.deduction}</b></span></p>
        </div>
    );
  }


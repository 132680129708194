import React, { Component } from 'react';
import { ArrowLeftCircleFill } from 'react-bootstrap-icons';
import NumberFormat from 'react-number-format';

class Calculator extends Component {
    state = { 
        color6: '#3A974C',
        deduction6: '0.00',
        totalEstDeduction6: '0.00',
        deduction12: '0.00',
        totalEstDeduction12: '0.00',
        color12: '#3A974C',
        desiredLoanAmount: null,
        loanDeduction: null
    } 
    
    computeLoan = (desiredLoanAmount, loanDeduction) => {
        const dla = parseFloat(desiredLoanAmount.replace(/,/g,''));
        const ld = parseFloat(loanDeduction.replace(/,/g,''));

        const minusPerCutOff6 = dla/6;
        const minusPerCutOff12 = dla/12;
        const totalLoan6 = minusPerCutOff6 + ld;
        const totalLoan12 = minusPerCutOff12 + ld;

        this.setState({
            color6: this.evaluateTheme(totalLoan6, dla),
            deduction6: this.formatNum(minusPerCutOff6),
            totalEstDeduction6: this.formatNum(totalLoan6),
            deduction12: this.formatNum(minusPerCutOff12),
            totalEstDeduction12: this.formatNum(totalLoan12),
            color12: this.evaluateTheme(totalLoan12, dla)
        });
    }
    
    evaluateTheme = (totalLoanDeductions, desiredLoanAmount) => {
        const green = '#3A974C'
        const red = '#D11A2A'
        return totalLoanDeductions < desiredLoanAmount ? green : red;
    }
    
    hexToRgb = (hex) => {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, 0.1)`
            : null;
    }

    formatNum = (num) => {
        return isNaN(num) ? 0 : Number(num.toFixed(2)).toLocaleString('en');
    }

    render() { 
        const { color6, 
            deduction6,
            totalEstDeduction6,
            color12,
            deduction12,
            totalEstDeduction12,
            desiredLoanAmount,
            loanDeduction } = this.state; 
        const style6 = {
            color: color6,
            background: this.hexToRgb(color6)
        }
        const style12 = {
            color: color12,
            background: this.hexToRgb(color12)
        }

        return (
            <div>
                <div className='top-left'>
                <ArrowLeftCircleFill style={{'fontSize': '24px'}}/> <a href="https://sites.google.com/shopee.com/shopee-ph-intranet"> Back to Intranet</a>
                </div>
                <div className='container'>
                    <div className='calculator'>
                        <h1 >Emergency Loan Calculator</h1>
                        <span className='span0'><b>DISCLAIMER:</b> This is only a tool that allows you to compute your loan eligibility and project monthly deduction per cut-off. For better reference, you may check your payslip via HRIS for actual deductions of existing loans, should you have any. <br></br><small><b>NOTE: Subject to HR Approval</b></small></span>
                    </div>
                    <form className='calc-form' autoComplete='off'>
                        <div className='form-group'>
                            <label>Desired Loan Amount <br></br>(up to <b>50%</b> of the salary) </label>
                            <span className='special'>₱ </span>
                            <NumberFormat thousandSeparator={true} placeholder='0.00' onInput={e => this.setState({'desiredLoanAmount': e.target.value})}></NumberFormat>
                        </div>
                        <div className='form-group'>
                            <label>Total Loan Deductions <br></br>(Statutory Loans, check from your payslip)</label>
                            <span className='special'>₱ </span>
                            <NumberFormat thousandSeparator={true} placeholder='0.00' onInput={e => this.setState({'loanDeduction': e.target.value.toLocaleString()})}></NumberFormat>
                        </div>
                        <div className='form-group'>
                            <input className='btn' type='button' value='Compute' onClick={() => this.computeLoan(desiredLoanAmount,loanDeduction)}></input>
                        </div>
                        
                    </form>

                    <h3>Result</h3>
                    <div className='block-wrapper'>
                        <div className='block' style={style6}>
                            <h4>3 Months Deduction</h4>
                            <p className='p1'>Deduction for 6 succeeding paydays:<br></br><span className='span1'>₱ {deduction6}</span></p>
                            <p className='p1'>Total Estimated Deduction (Statutory + Employee Loan)<br></br><span className='span2'>₱ {totalEstDeduction6}</span></p>
                        </div>
                        <div className='block' style={style12}>
                            <h4>6 Months Deduction</h4>
                            <p className='p1'>Deduction for 12 succeeding paydays:<br></br><span className='span1'>₱ {deduction12}</span></p>
                            <p className='p1'>Total Estimated Deduction (Statutory + Employee Loan)<br></br><span className='span2'>₱ {totalEstDeduction12}</span></p>
                        </div>
                    </div>

                    <div className='small-block'>
                        <p><b>NOTE</b><br></br>
                        <span style={{'color': '#3A974C'}}>Green</span> if total loan deductions &lt;50% salary<br></br>
                        <span style={{'color': '#D11A2A'}}>Red</span> if total loan deductions &gt;50% salary 
                        </p>

                    </div>

                </div>
            </div>
            
        );

        
    }
}
 
export default Calculator;
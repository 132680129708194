import React, { Component } from 'react';
import EmergencyCalculator from './components/EmergencyCalculator';
import { useState } from "react";
import PersonalCalculator from './components/PersonalCalculator';

class Base extends Component {

    constructor(props) {
        super(props);
        this.state = {value: 'Salary Loan'};
        this.handleChange = this.handleChange.bind(this);
      }

      handleChange(event) {
        this.setState({value: event.target.value});
      }
    render(){
        return(
            <div>
                <div className='top-right'>
                    <label>Select Type of Loan: </label>
                        <select value={this.state.value} onChange={this.handleChange}>
                            <option value="Salary Loan">Salary Loan</option>
                            <option value="Emergency Loan">Emergency Loan</option>
                          
                        </select>
                  
                </div>  
                {this.state.value==='Emergency Loan' ? <EmergencyCalculator/> : <PersonalCalculator />}
            </div>

        )
    }
}

export default Base;
import '../index.css';

export function Error() {
    return(
        <div>
            <p  className='error'>The Desired Loan Amount exceeds the eligible loan amount! </p>
        </div>
    );
  }

